import React from 'react'

import { ExternalLink, PageHeading } from '@broad/ui'

import DocumentTitle from './DocumentTitle'
import InfoPage from './InfoPage'
import Link from './Link'

export default () => (
  <InfoPage>
    <DocumentTitle title="Terms and Data Information" />
    <PageHeading>Terms and Data Information</PageHeading>

    <h2>Terms of use</h2>
    <p>
	The PKD Genome Browser is funded by the National Institute of Diabetes and Digestive and Kidney Diseases (NIDDK). <br /><br />
	 </p>

    <p>
      <b>Appropriate Data Usage:</b> The database is meant to be used for research purposes only. 
      The database contains all variants found in the sequenced population of individuals with polycystic kidney disease. 
      The presence of a variant in this population does not indicate that it is pathogenic. The data is not intended to be used for the provision of clinical care.
    </p>

    <p>
    <b>Citing:</b> Publications that use the PKD Genome Browser should cite (www.pkdgenes.org) Grants: U24DK126110 and U54 DK126114.  
	There is no need to include us as authors on your manuscript, unless we contributed specific advice or analysis for your work.
   </p>
  
  </InfoPage>
)
