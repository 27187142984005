import React from 'react'
import styled from 'styled-components'
import { ExternalLink, PageHeading } from '@broad/ui'

import DocumentTitle from './DocumentTitle'
import InfoPage from './InfoPage'

const Credits = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 13px;

  @media (max-width: 992px) {
    flex-direction: column;
    font-size: 16px;
  }
`

const CreditsSection = styled.div`
  width: calc(${props => props.width} - 15px);

  @media (max-width: 992px) {
    width: 100%;
  }
`

const ContributorList = styled.ul`
  list-style-type: none;
  padding-left: 0;
  line-height: 1.5;

  ul {
    padding-left: 20px;
    margin: 0.5em 0;
    list-style-type: none;
  }
`

const PrincipalInvestigatorList = styled(ContributorList)`
  columns: 2;

  @media (max-width: 992px) {
    columns: 1;
  }
`

const FundingSourceList = styled(ContributorList)`
  li {
    margin-bottom: 1em;
  }
`

const TableStyled = styled.table`
  border: 1px solid black;
  border-collapse: collapse;
`

const TableHeader = styled.th`
  border: 1px solid black;
  border-collapse: collapse;
`

const TableData = styled.td`
  border: 1px solid black;
  border-collapse: collapse;
`


export default () => (
  <InfoPage>
    <DocumentTitle title="About SFARI" />
    <PageHeading id="about-sfari">About PKD Browser</PageHeading>
    <p>
      The PKD Genome Browser was conceived by the PKD Research Resource Consortium and is modeled on the gnomAD database. Our goal is to aggregate and harmonize 
      exome and genome sequencing data from an array large-scale sequencing projects of individuals with all forms of polycystic kidney disease.   
      The genome browser provides summary data to the broad scientific community. <br /><br /> 

      The project is overseen by Monkol Lek, Whitney Besse, Stefan Somlo and Terry Watnick.  
      
      A list of investigators and groups that have contributed data is available below:
      <ul>
      <li><ExternalLink href="https://medicine.yale.edu/profile/stefan-somlo/">Stefan Somlo</ExternalLink></li>
      <li><ExternalLink href="https://medicine.yale.edu/profile/whitney-besse/">Whitney Besse</ExternalLink></li>
      <li><ExternalLink href="https://www.medschool.umaryland.edu/profiles/Watnick-Terry/">Terry Watnick</ExternalLink></li>
      </ul>

    </p>
 
     <h2>Data Processing</h2>
     <p>
      The raw data from contributing cohorts have been reprocessed through equivalent pipelines, and jointly variant-called to increase consistency across cohorts. 
      The processing pipelines were written in the WDL workflow definition language and executed using the Cromwell execution engine, open-source projects for 
      defining and executing genomic workflows at massive scale on multiple platforms. The PKD Genome Browser data set contains individuals sequenced using multiple 
      exome and targeted capture methods and sequencing chemistries. The resulting joint called vcf was QCed and analyzed using the Hail open-source framework 
      for scalable genetic analysis. 
    </p>
 
    <h2>External data</h2>
     <p>
      The gene and variant page for PKD1 and PKD2 contains data obtained from the <ExternalLink href="https://pkdb.mayo.edu/">Mayo PKD Variant Database.</ExternalLink> 
       Currently the link on the variant page (e.g. <ExternalLink href="https://pkdgenes.org/variant/16-2090943-G-A">16-2090943-G-A</ExternalLink> ) is an API call 
      for the specific variant (i.e. <ExternalLink href="https://adpkd-api.mayo.edu/api/Variants/3/3909">this link</ExternalLink> for 16-2090943-G-A). 
      This link will be updated once a variant specific formatted page can be generated by the Mayo database. 
    </p>
    <p>
      <h2>Development Team</h2>
      The <ExternalLink href="https://github.com/leklab/pkd_browser">PKD Browser code</ExternalLink> is based on the <ExternalLink href="https://github.com/broadinstitute/gnomad-browser">gnomAD Browser</ExternalLink> and developed by Monkol Lek (Yale University).
    </p>

    <p>
      <h2>Funding</h2>
      The PKD Genome Browser is funded by the National Institute of Diabetes and Digestive and Kidney Diseases (NIDDK). Grants: U24DK126110 and U54 DK126114.  
    </p>

    <p>
      <h2>Acknowledgements</h2>
    We would like to thank all the patients, investigtors, and biorepositories who contributed DNA samples and/or sequencing.  This includes the following:<br />
    <ExternalLink href="https://repository.niddk.nih.gov/studies/crisp1">Consortium for Radiologic Imaging Studies of Polycystic Kidney Disease (CRISP)</ExternalLink> <br /> 
    <ExternalLink href="https://repository.niddk.nih.gov/studies/halt_pkd_a/">The HALT Progression of Polycystic Kidney Disease (HALT PKD)</ExternalLink>
    </p>

<br /><br /><br /><br />


  </InfoPage>
)
