import LinkIcon from '@fortawesome/fontawesome-free/svgs/solid/link.svg'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import { ExternalLink, PageHeading } from '@broad/ui'

import DocumentTitle from './DocumentTitle'
import InfoPage from './InfoPage'
import SampleCountTable from './SampleCountTable'

const AnchorLink = styled.a.attrs({ 'aria-hidden': 'true' })`
  position: absolute;
  top: 0;
  left: -15px;
  display: flex;
  align-items: center;
  width: 15px;
  height: 1em;
  visibility: hidden;
  vertical-align: middle;
`

const AnchorWrapper = styled.span`
  position: relative;

  :hover {
    ${AnchorLink} {
      visibility: visible;
    }
  }
`

const withAnchor = Component => {
  const ComposedComponent = ({ children, id }) => (
    <AnchorWrapper>
      <Component>
        <AnchorLink href={`#${id}`} id={id}>
          <LinkIcon height={12} width={12} />
        </AnchorLink>
        {children}
      </Component>
    </AnchorWrapper>
  )
  const componentName = Component.displayName || Component.name || 'Component'
  ComposedComponent.displayName = `withAnchor(${componentName})`
  ComposedComponent.propTypes = {
    children: PropTypes.node.isRequired,
    id: PropTypes.string.isRequired,
  }
  return ComposedComponent
}

const FAQSectionHeading = withAnchor(styled.h2``)

const Question = withAnchor(
  styled.dt`
    margin-bottom: 0.5em;
    font-weight: bold;
  `
)

const Answer = styled.dd`
  margin: 0 0 1em;
`

export default () => (
  <InfoPage>
    <DocumentTitle title="FAQ" />
    <PageHeading>Frequently Asked Questions</PageHeading>

    <p>
    <b>What do I do if I want to contribute data?</b><br />
    <i>Email: <ExternalLink href="mailto:info@pkd-rcc.org">info@pkd-rcc.org</ExternalLink></i>
    </p>

    <p>
    <b>Which patients are included in this database?</b><br />
    <i>Only patients given a clinical diagnosis of ADPKD are included in this database. 
    Genetic diagnosis was not required, nor was eligibility reassessed after genetic testing.  Only one member per family is represented. </i>
    </p>

    <p>
    <b>How should I interpret the disease-relevance of a variant in this database?</b><br />
    <i>Variant interpretation can make use of provided data including minor allele frequency (gnomAD frequency provided), 
    in silico prediction algorithms (PolyPhen, SIFT, REVEL, CADD, SpliceAI, PrimateAI scores are provided when available), 
    and public databases (assessment from the <ExternalLink href="https://pkdb.mayo.edu/">Mayo ADPKD database</ExternalLink> is provided on the variant page, 
    link to the <ExternalLink href="https://www.ncbi.nlm.nih.gov/clinvar/">Clinvar</ExternalLink> annotation of the precise variant is available on the variant page). To protect patient privacy, individual-level data about genetic 
    diagnosis will not be provided.</i>
    </p>

    <p>
    <b>What human genome reference was used?</b><br />
    <i>All data was reprocessed using a common pipeline and aligned to <b>GRCh38.</b></i>
    </p>


  </InfoPage>
)
